<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="primary--text">{{title}}</span>
      </v-card-title>
      <v-form
        ref="fetchAgenteCta-form"
        id="fetchAgenteCta-form"
        @submit.prevent="fetchAgenteCta()"
      >
        <v-row class="p-3 pb-0">
          <v-col cols="12" sm="12" md="4">
            <v-text-field
              v-model="nombreAgenteCta"
              label="Persona o razón social"
              item-text="value"
              item-value="id"
              type="text"
              outlined
              clearable
              dense
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="3">
            <v-text-field
              v-model="documentoAgecteCta"
              v-mask="['#.###.###','##.###.###']"
              label="N° de documento"
              item-text="value"
              item-value="id"
              onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
              outlined
              clearable
              dense
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="3">
            <v-text-field
              v-model="cuitAgecteCta"
              v-mask="'##-########-#'"
              label="CUIL/CUIT"
              item-text="value"
              item-value="id"
              onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
              outlined
              clearable
              dense
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="2">
            <v-btn
              color="primary"
              type="submit"
              form="fetchAgenteCta-form"
            >
              Buscar
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <v-form
        v-model="isFormValid"
        ref="selectAgeCta-form"
        id="selectAgeCta-form"
        @submit.prevent="selectAgeCta()"
      >
        <v-row>
          <v-col>
            <v-data-table
              v-model="agectaSelected"
              :headers="headersAgectaBusqueda"
              :items="itemsAgectaBusqueda"
              :loading="isLoading"
              single-select
              item-key="agenteId"
              show-select
              dense
              class="elevation-1"
              :items-per-page="5"
            />
          </v-col>
        </v-row>
        <v-row class="d-flex justify-end">
          <v-col cols="2">
            <v-btn outlined @click="closeModal()">
              Volver
            </v-btn>
          </v-col>
          <v-col cols="2" class="d-flex justify-end">
            <v-btn
              color="primary"
              type="submit"
              ref="selectAgeCta-form"
              :disabled="agectaSelected.length == 0"
            >
              Seleccionar
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import { mask } from "vue-the-mask";
import enums from "@/utils/enums/index.js";

export default {
  name: "BuscarAgenteCuenta",
  directives: { mask },
  data() {
    return {
      enums: enums,
      title: enums.titles.BUSCAR_AGENTE_CUENTA,
      nombreAgenteCta: null,
      documentoAgecteCta: null,
      cuitAgecteCta: null,
      agectaSelected: [],
      tiposAgentes: [],
      itemsAgectaBusqueda: [],
      isFormValid: false,
      isLoading: false,
      headersAgectaBusqueda: [
        {
          text: "Código",
          align: "start",
          sortable: false,
          value: "agenteId"
        },
        { text: "Agente de cuenta", value: "nombre", align: "start" },
        { text: "N° de documento", value: "documento", align: "start" },
        { text: "CUIT", value: "cuit", align: "start" }
      ]
    };
  },
  methods: {
    ...mapActions({
      getAgenteCuentaByCodigo: "afiliaciones/getAgenteCuentaByCodigo",
      setAlert: "user/setAlert"
    }),
    closeModal() {
      this.$emit("toggleModalBusquedaAgecta");
    },
    selectAgeCta() {
      this.$emit("toggleModalBusquedaAgecta");
      this.$emit("findAgenteCta", this.agectaSelected[0])
    },
    async fetchAgenteCta() {
      this.isLoading = true
      const result = await this.getAgenteCuentaByCodigo({
        codigo: "",
        nombre: this.nombreAgenteCta ?? "",
        dni: this.documentoAgecteCta == null ? "" : this.documentoAgecteCta,
        cuit: this.cuitAgecteCta == null ? "" : this.cuitAgecteCta
      });
      this.itemsAgectaBusqueda = result;
      this.isLoading = false
    }
  }
};
</script>

<style></style>
