var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showExpand),expression:"!showExpand"}],staticClass:"pr-0 pt-5 pb-0",attrs:{"cols":"12","md":_vm.showHelp ? 8 : 11,"sm":12}},[_c('GoBackBtn',{attrs:{"routeToGo":_vm.routeToGo,"tabId":false}}),_c('PageHeader',{staticClass:"pt-5 pb-2",attrs:{"title":_vm.title}}),_c('v-card',{staticClass:"mx-auto mb-4 pt-0",attrs:{"max-width":"1200","outlined":""}},[_c('v-container',{staticClass:"pt-0"},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"10","align":"left"}},[(!_vm.showFilters)?_c('FiltersSelected',{attrs:{"filters":_vm.filtersApplied}}):_vm._e()],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"2","align":"right"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"primary--text",on:{"click":function($event){$event.stopPropagation();_vm.showFilters = !_vm.showFilters}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.closeFiltersIcon)+" ")])]}}])},[_c('span',[_vm._v(_vm._s(!_vm.showFilters ? "Mostrar filtros" : "Ocultar filtros"))])])],1)],1),_c('v-expand-transition',{attrs:{"mode":"out-in"}},[_c('v-form',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFilters),expression:"showFilters"}],ref:"filters-form",attrs:{"id":"filters-form"},on:{"submit":function($event){$event.preventDefault();return _vm.loadDevengAdicionalesExtra()}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"4","md":"3"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('#########'),expression:"'#########'"}],attrs:{"type":"text","outlined":"","dense":"","clearable":"","label":"Código de agente","autocomplete":"off"},on:{"change":_vm.buscarAgente},model:{value:(_vm.codigoAgenteCta),callback:function ($$v) {_vm.codigoAgenteCta=$$v},expression:"codigoAgenteCta"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"4","md":"4"}},[_c('v-text-field',{attrs:{"type":"text","outlined":"","dense":"","clearable":"","disabled":"","label":"Nombre de agente de cuenta","autocomplete":"off"},model:{value:(_vm.nombreAgenteCta),callback:function ($$v) {_vm.nombreAgenteCta=$$v},expression:"nombreAgenteCta"}})],1),_c('v-col',{staticClass:"py-0 px-0",attrs:{"cols":"1","md":"1"}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"absolute":"","fab":"","small":"","color":"primary","disabled":_vm.searchAgenteBtn},on:{"click":function($event){_vm.modalBusquedaAgecta = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.searchIcon))])],1)]}}])},[_c('span',[_vm._v("Buscar agente de cuenta")])])],1),_c('v-col',{staticClass:"py-0 px-0",attrs:{"cols":"3","md":"3"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('######'),expression:"'######'"}],ref:"periodo",attrs:{"label":"Período","outlined":"","dense":"","clearable":"","onkeypress":"return (event.charCode >= 48 && event.charCode <= 57)","rules":_vm.rules.required.concat(_vm.rules.periodoYyyyMm),"hint":"Formato AAAAMM","persistent-hint":""},model:{value:(_vm.periodo),callback:function ($$v) {_vm.periodo=$$v},expression:"periodo"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"6","md":"4"}},[_c('v-autocomplete',{attrs:{"items":_vm.tiposComprobantes,"label":"Tipo de comprobante","item-text":"value","item-value":"id","outlined":"","clearable":"","dense":""},model:{value:(_vm.tipoComprobanteSelected),callback:function ($$v) {_vm.tipoComprobanteSelected=$$v},expression:"tipoComprobanteSelected"}})],1)],1),_c('v-row',{staticClass:"pr-4 pl-4",attrs:{"justify":"end"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"size":"28"},on:{"click":_vm.resetForm}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.clearFiltersIcon)+" ")])]}}])},[_c('span',[_vm._v("Limpiar filtros")])]),_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.isFormValid,"elevation":"2","small":"","type":"submit","form":"filters-form"}},[_vm._v(" Aplicar ")])],1)],1)],1)],1)],1),_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.itemsDevengamientoAdicionalesExtra,"search":_vm.search,"show-select":"","loading":_vm.isLoading,"item-key":"adicionalExtraId"},scopedSlots:_vm._u([{key:"item.tieneIva",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v(" "+_vm._s(item.tieneIva ? _vm.checkIcon : "")+" ")])]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{attrs:{"append-icon":_vm.searchIcon,"label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1)]},proxy:true}],null,true),model:{value:(_vm.registrosSeleccionados),callback:function ($$v) {_vm.registrosSeleccionados=$$v},expression:"registrosSeleccionados"}},[[(_vm.registrosSeleccionados.length > 0)?_c('v-icon',[_vm._v(" mdi-checkbox-marked")]):_vm._e()]],2)],1),[_c('v-card',{staticClass:"mx-auto mb-5 mt-2 pt-0",attrs:{"max-width":"1200","outlined":""}},[_c('v-container',{staticClass:"pt-0"},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"4","md":"3"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"label":"Fecha de emisión","append-icon":_vm.calendarIcon,"hint":"formato DD/MM/AAAA","onkeypress":"return (event.charCode >= 47 && event.charCode <= 57)","clearable":"","dense":"","outlined":"","disabled":_vm.itemsDevengamientoAdicionalesExtra.length == 0 ||
                          _vm.registrosSeleccionados.length == 0,"autocomplete":"off","rules":_vm.fechaEmision
                          ? _vm.rules.validDate.concat(
                              new Date(_vm.parseDateToIso(_vm.fechaEmision)) <=
                                new Date(_vm.parseDateToIso(_vm.fechaVencimiento)) ||
                                'Formato incorrecto'
                            )
                          : []},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }_vm.menu1 = false},"blur":function($event){[(_vm.fechaEmi = _vm.parseDateToIso(_vm.fechaEmision))]},"change":function($event){_vm.fechaEmision ? _vm.convertDateToPeriodo() : null}},model:{value:(_vm.fechaEmision),callback:function ($$v) {_vm.fechaEmision=$$v},expression:"fechaEmision"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"change":function($event){[(_vm.fechaEmision = _vm.formatDate(_vm.fechaEmi))]},"input":function($event){_vm.menu1 = false}},model:{value:(_vm.fechaEmi),callback:function ($$v) {_vm.fechaEmi=$$v},expression:"fechaEmi"}})],1)],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"4","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"label":"Fecha de vencimiento","append-icon":_vm.calendarIcon,"onkeypress":"return (event.charCode >= 47 && event.charCode <= 57)","hint":"formato DD/MM/AAAA","clearable":"","dense":"","disabled":_vm.itemsDevengamientoAdicionalesExtra.length == 0 ||
                          _vm.registrosSeleccionados.length == 0,"rules":_vm.fechaVencimiento
                          ? _vm.rules.validDate.concat(
                              new Date(_vm.parseDateToIso(_vm.fechaEmision)) <=
                                new Date(_vm.parseDateToIso(_vm.fechaVencimiento)) ||
                                'Formato incorrecto'
                            )
                          : [],"outlined":"","autocomplete":"off"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }_vm.menu2 = false},"blur":function($event){[(_vm.fechaVenc = _vm.parseDateToIso(_vm.fechaVencimiento))]},"change":function($event){[(_vm.fechaVenc = _vm.parseDateToIso(_vm.fechaVencimiento))]}},model:{value:(_vm.fechaVencimiento),callback:function ($$v) {_vm.fechaVencimiento=$$v},expression:"fechaVencimiento"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"change":function($event){[(_vm.fechaVencimiento = _vm.formatDate(_vm.fechaVenc))]},"input":function($event){_vm.menu2 = false}},model:{value:(_vm.fechaVenc),callback:function ($$v) {_vm.fechaVenc=$$v},expression:"fechaVenc"}})],1)],1)],1),_c('v-row',{staticClass:"pt-14 mt-6"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","md":"7","align":"right"}},[_c('v-tooltip',{attrs:{"top":"","max-width":"40%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var attrs = ref.attrs;
return [_c('v-btn',_vm._b({staticClass:"fontsize",attrs:{"color":"primary","outlined":"","disabled":_vm.itemsDevengamientoAdicionalesExtra.length == 0},on:{"click":_vm.exportExcelModelo}},'v-btn',attrs,false),[_vm._v(" Exportar detalle ")])]}}])})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"3","md":"1","align":"right"}},[_c('v-tooltip',{attrs:{"top":"","max-width":"40%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){return [_c('v-btn',{staticClass:"fontsize",attrs:{"color":"primary","disabled":_vm.itemsDevengamientoAdicionalesExtra.length == 0,"outlined":""},on:{"click":function($event){return _vm.exportErrores()}}},[_vm._v(" Ver errores ")])]}}])})],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"70rem"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"3","md":"11","align":"right"}},[_c('v-btn',_vm._g(_vm._b({staticClass:"to-right",attrs:{"disabled":!_vm.fechaEmision || !_vm.fechaVencimiento,"color":"primary"},on:{"click":function($event){return _vm.generarComprobante(_vm.registrosSeleccionados)}}},'v-btn',attrs,false),on),[_vm._v("Generar comprobantes")])],1)],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersComprobantes,"items":_vm.itemsComprobantes,"search":_vm.search,"loading":_vm.isLoading},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('v-text-field',{attrs:{"append-icon":_vm.searchIcon,"label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1)]},proxy:true}])}),_c('v-card-actions',{staticClass:"m-3"},[_c('v-spacer'),_c('v-col',{staticClass:"p-1 m-2",attrs:{"cols":"3","md":"3"}},[_c('v-tooltip',{attrs:{"top":"","max-width":"35%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var attrs = ref.attrs;
return [_c('v-btn',_vm._b({staticClass:"to-right",attrs:{"color":"primary","outlined":""},on:{"click":_vm.exportComprobantesGenerados}},'v-btn',attrs,false),[_vm._v(" Exportar lista completa ")])]}}])})],1),_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){return _vm.closeModalResultadoComprobantes()}}},[_vm._v(" Cerrar ")])],1)],1)],1)],1)],1)],1)]],2),_c('v-col',{staticClass:"to-right pt-13 px-0",attrs:{"cols":"12","md":!_vm.showHelp ? 1 : _vm.showExpand ? 12 : 4}},[_c('Ayuda',{attrs:{"optionCode":_vm.optionCode,"pantallaTitle":_vm.title},on:{"toggleExpand":function($event){_vm.showExpand = !_vm.showExpand},"toggleHelp":function($event){return _vm.toggleHelp()},"toggleIcon":function($event){return _vm.toggleIcon()}}})],1)],1),(_vm.modalBusquedaAgecta)?_c('v-dialog',{attrs:{"max-width":"65%","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.toggleModalBusquedaAgecta($event)}},model:{value:(_vm.modalBusquedaAgecta),callback:function ($$v) {_vm.modalBusquedaAgecta=$$v},expression:"modalBusquedaAgecta"}},[_c('BuscarAgenteCuenta',{on:{"toggleModalBusquedaAgecta":_vm.toggleModalBusquedaAgecta,"findAgenteCta":_vm.findAgenteCta}})],1):_vm._e(),_c('ConfirmDialog',{attrs:{"text":_vm.textConfirmDialog,"openConfirm":_vm.openConfirmDialog},on:{"update:openConfirm":function($event){_vm.openConfirmDialog=$event},"update:open-confirm":function($event){_vm.openConfirmDialog=$event},"onConfirm":function($event){return _vm.exportErrores(_vm.itemsConErrores)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }